body {
  font-family: "Fira Code", monospace;
  background-color: black;
}

.App {
  text-align: center;
  position: relative;
  overflow: hidden;
  height: 100vh;
  width: 100vw;
}

.canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.App-header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  overflow: hidden;
}

p {
  margin-bottom: 0.5rem;
}

h1 {
  font-size: 3rem;
  margin-bottom: 0.5rem;
}

h2 {
  font-size: 2rem;
  margin-bottom: 0.5rem;
}

.contact-button {
  padding: 15px 20px;
  background-color: #212121;
  color: white;
  font-size: 14px;
  border-radius: 5px;
  text-decoration: none;
  font-weight: 900;
  transition: transform 0.3s ease, box-shadow 0.3s ease; 
}

.contact-button:hover {
  box-shadow: 0 0 3px rgba(255, 255, 255, 0.438);  /* White glow */
}

input {
  padding: 10px 20px;
  border-radius: 5px;
  border: 1px solid #212121;
  font-size: 18px;
  background-color: #131313;
  color: white;
  height: 1.5rem;
  width: 50%;
  outline: none;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 700px;
  padding: 1rem;
}

/* make row responsive */
@media (max-width: 700px) {
  .row {
    flex-direction: column;
  }

  .contact-button {
    width: 50%;
  }
}


.subform {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  width: 100%;
}

.error-text {
  color: crimson;
  font-size: 0.8rem;
  margin-top: 0;
  margin-bottom: 20px;
}

.success-text {
  color: seagreen;
  font-size: 0.8rem;
  margin-top: 0;
  margin-bottom: 20px;
}